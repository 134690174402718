
import OpenSupport from "@/mixins/openSupport"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import IosMediaDialog from "@evercam/shared/components/medias/IosMediaDialog"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: {
    EvercamLoadingAnimation,
    IosMediaDialog,
  },
  mixins: [OpenSupport],
  data() {
    return {
      menu: false,
      hideTooltip: false,
      isLoadingZohoSupport: false,
      iosMediaDialog: false,
    }
  },
  watch: {
    menu() {
      this.hideTooltip = this.menu
    },
  },
  methods: {
    openUrlForIos(url) {
      if (!this.$device.isIos) {
        return
      }
      this.iosMediaDialog = true
      this.iosMediaUrl = url
      this.menu = false
    },
    onTutorialsClicked() {
      this.$analytics.saveEvent(AnalyticsEvent.HelpMenuClickTutorialsLink)
    },
    onUpdatesLinkClicked() {
      this.$analytics.saveEvent(AnalyticsEvent.HelpMenuClickWhatsNewLink)
      this.openUrlForIos(this.$config.public.productUpdatesUrl)
    },
    onUserManualLinkClicked() {
      this.$analytics.saveEvent(AnalyticsEvent.HelpMenuClickUserManuaLink)
      this.openUrlForIos(this.$config.public.userManualUrl)
    },
    onSupportTicketLinkClicked() {
      this.$analytics.saveEvent(AnalyticsEvent.HelpMenuClickSupportTicketsLink)
      this.openUrlForIos(this.$config.public.supportUrl)
    },
  },
}
